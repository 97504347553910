import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "react-toastify/dist/ReactToastify.min.css";
import App from "./App";
// import reportWebVitals from 'reportWebVitals';
import "./helpers/i18n";
import { Provider } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { ThemeProvider } from "@emotion/react";

import { store } from "./logic";



const node_root = document.getElementById("demenagement");

const theme = {
  color1: node_root.dataset.color1,
  color2: node_root.dataset.color2,
};

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </Suspense>
    <ToastContainer position={toast.POSITION.TOP_CENTER} />
  </React.StrictMode>,
  node_root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
