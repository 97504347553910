import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Global, css } from "@emotion/react";

import { setVisibleInputPhone } from "./logic/actions/param";
import HomePage from "./pages/home";

const App = ({ setVisibleInputPhone }) => {
 // ReactGA.pageview("/home/step0");
  const [fontName, setFontName] = useState("Open Sans");
  useEffect(() => {
    const node_root = document.getElementById("demenagement");
    const font_name = node_root.dataset.font;
    setFontName(font_name ? font_name : "");
    if (node_root.dataset.phone === "true") {
      setVisibleInputPhone(true);
    }
  }, []);

  return (
    <div className="container">
      <Global
        styles={css`
          @import url("https://fonts.googleapis.com/css2?family=${fontName
            .split(" ")
            .join("+")}");

          #demenagement {
            font-family: ${fontName && fontName};
          }
        `}
      />
      <HomePage />
         <p><small className="cgv">*Champs obligatoires.
Informations collectées par Bemove et les partenaires habilités pour vous renseigner sur des offres déménagement et vous proposer un service d'accompagnement à l'emménagement. Pour en savoir plus et exercer vos droits, accédez à notre <a href="https://www.bemove.fr/charte-de-confidentialite-et-cookies">politique de confidentialité des données</a>. Pour vous opposer à tout démarchage téléphonique, inscrivez-vous gratuitement sur la <a href="https://www.bloctel.gouv.fr/">liste Bloctel</a>.</small></p>    
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  setVisibleInputPhone: bindActionCreators(setVisibleInputPhone, dispatch),
});

export default connect(null, mapDispatchToProps)(App);
