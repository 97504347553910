import { createReducer } from "@reduxjs/toolkit";
import {
  RELOAD_BASES,
  SET_ACTIVE_KEY_STEP,
  SET_DATA_HOME_FORM,
  SET_EMAIL,
  SET_LIST_ACTIVE_STEP,
} from "logic/actions/base";

const initialState = {
  listActiveStep: ["step1"],
  activeKeyStep: "step1",
  phone: null,
  email: "",
  nom: "",
  depart: { address: "" },
  destination: { address: "" },
  checkMark: false,
};

const handleSetDataHomeForm = (state, { payload }) => {
  state.email = payload.data.email;
  state.phone = payload.data.phone;
  state.nom = payload.data.nom;
  state.depart = payload.data.depart;
  state.destination = payload.data.destination;
  state.checkMark = payload.data.checkMark;
};

const handleSetListActiveStep = (state, { payload }) => {
  state.listActiveStep = payload.steps;
    
};

const handleSetActiveKeyStep = (state, { payload }) => {
  state.activeKeyStep = payload.step_name;
   
};

const handleReloadBases = (state) => {
  state.listActiveStep = ["step1"];
  state.activeKeyStep = "step1";
};

const handleSetEmail = (state, { payload }) => {
  state.email = payload.email;
};

export default createReducer(initialState, {
  [SET_DATA_HOME_FORM]: handleSetDataHomeForm,
  [SET_ACTIVE_KEY_STEP]: handleSetActiveKeyStep,
  [SET_LIST_ACTIVE_STEP]: handleSetListActiveStep,
  [RELOAD_BASES]: handleReloadBases,
  [SET_EMAIL]: handleSetEmail,
});
