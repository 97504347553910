import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";

const CustomCheckbox = ({ content, ...props }) => {
  const theme = useTheme();
  const BoxContainerCheckbox = styled.div`
    .check-mark {
      border: 1px solid ${theme.color1};
      &::before {
        border: solid ${theme.color1};
      }
    }
  `;
  
  return (
    <BoxContainerCheckbox className="checkbox mt-3">
      <label className="d-flex align-item-center">
        <input
          type="checkbox"
          aria-label="Checkbox for following text input"
          checked={props.value}
          {...props}
        />
        <span className="check-mark"></span>
        <span>{content}</span>

      </label>
    </BoxContainerCheckbox>
  );
};

export default CustomCheckbox;
